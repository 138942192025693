import { readFragment } from "@/data-access/graphql";
import {
  type DomainOrReadershipConfig,
  type FeedWithAugTypes,
  NewsFeedsListQuery,
  type SideBarQueryFeedType,
  UPDATE_KNOWN_TAGS_MUTATION,
  UpdateArticleMutation,
} from "@/data-access/news/newsFeeds";
import {
  type TierFragmentType,
  tierConfigurationFragment,
  tierFragment,
} from "@/data-access/news/tier";
import { client } from "@/lib/urqlProvider";
import type { ArticleEditFn } from "@/types/article";
import type { SortOptions, TopTopicsType } from "@/types/shared";
import { type StateCreator } from "zustand";
import {
  getDaysParametersFromUrl,
  getSearchTermsFromUrl,
  getSortByFromUrl,
  setDaysParametersToUrl,
  setSearchTermsToUrl,
  setSortByToUrl,
} from "../components/news/shared/utils";
import {
  type CategoryAppliedFilter,
  TIER_1,
  TIER_2,
  TIER_3,
  calculateNewCounts,
  clearExistingCounts,
  filterFeedItems,
  initCategoryAppliedFilters,
  updateAppliedFiltersToUrl,
} from "./feedHelpers";

export type FeedSlice = {
  feedName?: string;
  computed: {
    storyCount: number;
    articleCount: number;
    filterGroups: {
      key: string;
      title: string;
      filters: FilterItem[];
      sorted: boolean;
    }[];
  };
  feedSidebarData: SideBarQueryFeedType;
  updateFeedSideBarData: (data: SideBarQueryFeedType) => void;
  fetchingFeed: boolean;
  filterDays: number;
  startDate?: Date;
  endDate?: Date;
  removalArr: number[];
  sortOrder: SortOptions;
  searchTerms: string;
  feedItems: FeedWithAugTypes[];
  feedTerms?: string[] | undefined;
  knownTags: string[];
  feedPercent?: number;
  filteredFeedItems: Partial<FeedWithAugTypes>[];
  categoryAppliedFilters: CategoryAppliedFilter;
  feedId: number;
  tier2Max: number;
  tier3Max: number;
  refreshingScore: boolean;
  updateArticle: ArticleEditFn;
  fetchFeed: () => void;
  unsubscribeFetchFeed: () => void;
  setFeedId: (feedId: number) => void;
  updateDateRange: (days?: number, startDate?: Date, endDate?: Date) => void;
  removeArticle: (id: number) => void;
  updateFilterAndPercent: () => void;
  updateResultsSortBy: (sortOrder: SortOptions) => void;
  updateFilterCounts: (categoryKey?: string | false) => void;
  isFiltered: () => boolean;
  changeSort: (order: SortOptions) => void;
  filterBySearchTerms: (searchTerms: string) => void;
  updateCategoryAppliedFilters: (
    categoryApps: CategoryAppliedFilter,
    categoryKey?: string | false,
  ) => void;
  updateSelectedFilters: (categoryKey: string, selected: string[]) => void;
  updateRelationship: (filter: string, relationship: "AND" | "OR") => void;
  addEditTopTopic: (newTopic: string, oldValue?: string | false) => void;
  removeTopTopic: (topic: string) => void;
  tierConfig: Record<string, number>;
  syncTopTopics: (
    topTopics: TopTopicsType,
    feedId: number,
    name: string,
    terms: string[],
  ) => void;
  setTierConfig: (config: Record<string, number>) => void;
  recalculateFilterCount: () => void;
  updateTermsAndTags: () => void;
};

type FilterItem = {
  value: string;
  count: number;
  selected: boolean;
};

const DEFAULT_FILTER_DAYS = 30;

export const createFeedSlice: StateCreator<
  FeedSlice,
  [["zustand/devtools", never]],
  []
> = (set, get) => ({
  feedSidebarData: [],
  searchTerms: getSearchTermsFromUrl() ?? "",
  fetchingFeed: false,
  refreshingScore: false,
  filterDays: getDaysParametersFromUrl() || DEFAULT_FILTER_DAYS,
  categoryAppliedFilters: {} as CategoryAppliedFilter,
  feedItems: [],
  sortOrder: (getSortByFromUrl() as SortOptions) || "SCORE_DESC",
  filteredFeedItems: [],
  feedName: "",
  feedId: 0,
  feedTerms: [],
  knownTags: [],
  feedPercent: 0,
  tier2Max: 80,
  tier3Max: 60,
  removalArr: [],
  unsubscribeFetchFeed: () => {},

  computed: {
    get storyCount() {
      return (
        get()?.feedItems?.filter((item) => item.itemType === "STORY").length ??
        0
      );
    },
    get articleCount() {
      return (
        get()?.feedItems?.filter((item) => item.itemType === "ARTICLE")
          .length ??
        0 - get()?.removalArr.length ??
        0
      );
    },
    get filterGroups() {
      const categoryAppliedFilters = get()?.categoryAppliedFilters;
      if (!categoryAppliedFilters) return [];
      return Object.entries(categoryAppliedFilters).map(
        ([categoryKey, categoryValue]) => {
          let filters = Object.entries(categoryValue?.itemCounts ?? {}).map(
            ([filter, count]) => ({
              value: filter,
              count,
              selected: categoryValue.selected?.includes(filter) ?? false,
            }),
          );

          // Sort the Social Engagement filters specifically
          if (categoryKey === "Social Engagement") {
            filters = filters.sort((a, b) => {
              if (a?.value === "0") return 1;
              if (b?.value === "0") return -1;
              const aValue = parseInt(
                a?.value?.split("-")[0]?.replace(",", "") ?? "0",
              );
              const bValue = parseInt(
                b?.value?.split("-")[0]?.replace(",", "") ?? "0",
              );
              return bValue - aValue;
            });
          }

          return {
            key: categoryKey,
            title: categoryKey,
            filters,
            sorted: categoryValue.sorted ?? true,
          };
        },
      );
    },
  },

  updateArticle: async (changes) => {
    const { articleId, headline } = changes;

    const { feedItems, filteredFeedItems, feedId } = get();

    const updateItemFn = <T extends Partial<FeedWithAugTypes>>(item: T): T => {
      if (item?.articleId === +articleId) {
        if (headline) item.headline = headline;
      }
      return item;
    };

    const updatedFeedItems = feedItems.map(updateItemFn);
    const updatedFilteredFeedItems = filteredFeedItems?.map(updateItemFn);

    set(
      {
        feedItems: updatedFeedItems,
        filteredFeedItems: updatedFilteredFeedItems,
      },
      false,
      "updated article",
    );

    await client
      .mutation(UpdateArticleMutation, {
        input: {
          feedId,
          feedArticleId: +articleId,
          overrides: {
            headline,
          },
        },
      })
      .toPromise();
  },

  isFiltered: () =>
    get().searchTerms.length > 0 ||
    Object.values(get().categoryAppliedFilters)?.some(
      ({ selected }) => selected ?? []?.length > 0,
    ),

  setFeedId: async (feedId) => {
    set({ feedId, fetchingFeed: true }, false, "feed id set");
    get().unsubscribeFetchFeed();
    get().updateTermsAndTags();
    get().fetchFeed();
  },

  updateTermsAndTags: () => {
    const { feedId, feedSidebarData } = get();
    const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
    const { name, terms, knownTags } = currentFeed || {};

    set(
      {
        feedName: name,
        feedTerms: currentFeed ? terms?.map((term) => term.term) ?? [] : [],
        knownTags: currentFeed ? knownTags?.map((tag) => tag.tag) ?? [] : [],
      },
      false,
      "updated terms and tags",
    );
  },

  updateFeedSideBarData: (data) => {
    set(
      {
        feedSidebarData: data,
      },
      false,
      "update feed sidebar w/ data",
    );

    const { feedId } = get();

    if (!feedId) return;

    get().updateTermsAndTags();
  },

  fetchFeed: () => {
    const {
      feedId,
      sortOrder,
      filterDays: dayFilter,
      unsubscribeFetchFeed,
      startDate,
      endDate,
    } = get();
    if (!feedId) return;

    unsubscribeFetchFeed();

    const isoStartDate = startDate
      ? startDate.toISOString().split("T")[0]
      : undefined;
    const isoEndDate = endDate
      ? endDate.toISOString().split("T")[0]
      : undefined;

    const { unsubscribe } = client
      .query(NewsFeedsListQuery, {
        feedId,
        sortOrder,
        dayFilter,
        startDate: isoStartDate,
        endDate: isoEndDate,
      })
      .subscribe((result) => {
        const { data, stale } = result;
        if (data) {
          const { feedItems, feedTerms, feedName, knownTags } = data.feedData;

          function readConfigurationFragment(config: DomainOrReadershipConfig) {
            const settingEnable =
              "enableCustomTierScoring" in config.tenant
                ? config.tenant.enableCustomTierScoring
                : config.tenant.enableReadership;
            const settingTiers = readFragment(
              tierConfigurationFragment,
              config,
            );
            return {
              enable: settingEnable,
              frag: readFragment(tierFragment, settingTiers.tiers),
            };
          }

          // Utility function to get upper bound for a specified tier
          function getUpperBound(
            tiers: readonly TierFragmentType[],
            tierNumber: number,
          ) {
            return (
              tiers.find((tier) => tier.tier === tierNumber)?.upperBound ||
              undefined
            );
          }

          // Read tier configuration
          const tierConfig = readConfigurationFragment(data.tierConfiguration);
          const tierReadershipConfig = readConfigurationFragment(
            data.readershipConfiguration,
          );

          // Initialize tier bounds with default values
          let tier2Max = 80;
          let tier3Max = 60;

          // Update bounds if enabled
          if (tierConfig.enable) {
            tier2Max = getUpperBound(tierConfig.frag, 2) || tier2Max;
            tier3Max = getUpperBound(tierConfig.frag, 3) || tier3Max;
          }

          if (tierReadershipConfig.enable) {
            tier2Max = getUpperBound(tierReadershipConfig.frag, 2) || tier2Max;
            tier3Max = getUpperBound(tierReadershipConfig.frag, 3) || tier3Max;
          }

          const { tier1Pub, tier2Pub, tier3Pub } = data;

          const getTierLevelFromPublisher = (publisherId: number) => {
            if (tier1Pub.publishers.some((pub) => +pub.id === publisherId)) {
              return TIER_1;
            }
            if (tier2Pub.publishers.some((pub) => +pub.id === publisherId)) {
              return TIER_2;
            }
            if (tier3Pub.publishers.some((pub) => +pub.id === publisherId)) {
              return TIER_3;
            }
            return null;
          };

          const getTierLevelFromDomainAuthority = (domainAuthority: number) => {
            if (!tierConfig.enable) return null;

            if (domainAuthority < tier3Max) {
              return TIER_3;
            }
            if (domainAuthority < tier2Max) {
              return TIER_2;
            }
            return TIER_1;
          };

          const getTierLevelFromReadership = (readerCount: number) => {
            if (!tierReadershipConfig.enable) return null;

            if (readerCount < tier3Max) {
              return TIER_3;
            }
            if (readerCount < tier2Max) {
              return TIER_2;
            }
            return TIER_1;
          };

          const getTierPriority = (tier: string | null | false) => {
            switch (tier) {
              case TIER_1:
                return 1;
              case TIER_2:
                return 2;
              case TIER_3:
                return 3;
              default:
                return 3;
            }
          };

          const getTierLevel = (
            domainAuthority: number,
            publisherId: number,
            readerCount: number, // Added reader count parameter
          ) => {
            const tierFromPublisher = getTierLevelFromPublisher(publisherId);
            const tierFromDomainAuthority =
              getTierLevelFromDomainAuthority(domainAuthority);
            const tierFromReadership = getTierLevelFromReadership(readerCount);

            // Determine the final tier by considering all factors and enabled configurations
            const tiers: Array<string | null> = [
              tierFromDomainAuthority,
              tierFromReadership,
              tierFromPublisher,
            ].filter(Boolean) as Array<string>;

            if (tiers.length === 0) {
              return TIER_3;
            }

            // Find tier with the highest priority (lowest numerical value)
            const finalTier = tiers.reduce((prev, curr) =>
              getTierPriority(curr) < getTierPriority(prev) ? curr : prev,
            );

            return finalTier ?? TIER_3;
          };

          const updatedFeedItems = feedItems.map((item) => ({
            ...item,
            tierLevel: getTierLevel(
              item.maxDomainAuthority,
              item.articlePublisher?.id ?? -1,
              item.articleReadership,
            ),
          }));

          set(
            {
              feedPercent: undefined,
              categoryAppliedFilters: initCategoryAppliedFilters(),
              tier2Max,
              tier3Max,
              searchTerms: getSearchTermsFromUrl() ?? "",
              fetchingFeed: false,
              removalArr: [],
              knownTags,
              refreshingScore: false,
              feedName,
              feedItems: updatedFeedItems,
              feedTerms,
              filteredFeedItems: updatedFeedItems,
            },
            false,
            `data loaded for feed ${feedName}`,
          );
          get().updateFilterCounts();
        }
        if (stale) {
          set(
            {
              refreshingScore: true,
            },
            false,
            "data is stale, refreshing score",
          );
        }
      });
    set({ unsubscribeFetchFeed: unsubscribe }, false, "new unsubscribe fn");
  },

  updateFilterCounts: async (categoryKeyToIgnore = false) => {
    const { categoryAppliedFilters: updatedCategoryAppliedFilters } = get();

    calculateNewCounts(
      updatedCategoryAppliedFilters,
      categoryKeyToIgnore,
      get().filteredFeedItems,
      get().knownTags,
    );

    clearExistingCounts(categoryKeyToIgnore, updatedCategoryAppliedFilters);

    set(
      { categoryAppliedFilters: updatedCategoryAppliedFilters },
      false,
      "clear counts update filters",
    );

    await get().updateFilterAndPercent();

    calculateNewCounts(
      updatedCategoryAppliedFilters,
      categoryKeyToIgnore,
      get().filteredFeedItems,
      get().knownTags,
    );

    await get().updateFilterAndPercent();

    set(
      { categoryAppliedFilters: updatedCategoryAppliedFilters },
      false,
      "updated category applied filters",
    );
  },

  removeArticle: (id) =>
    set(
      {
        removalArr: [...get().removalArr, id],
        filteredFeedItems: get().filteredFeedItems.filter((feedItem) =>
          feedItem?.id ? feedItem.id !== id : true,
        ),
      },
      false,
      "removed article from feed",
    ),

  filterBySearchTerms: (searchTerms) => {
    setSearchTermsToUrl(searchTerms);
    set({ searchTerms }, false, "updated search terms");

    get().updateFilterAndPercent();
    get().updateFilterCounts();
  },

  async updateFilterAndPercent() {
    const {
      feedId,
      feedItems,
      searchTerms,
      categoryAppliedFilters,
      removalArr,
    } = get();
    const filteredFeedItems = await filterFeedItems(
      feedItems,
      searchTerms,
      categoryAppliedFilters,
      +feedId,
    );
    const totalStories = feedItems.length - removalArr.length;
    const filteredStoryCount = filteredFeedItems.length - removalArr.length;
    set(
      {
        filteredFeedItems: filteredFeedItems.filter((feedItem) =>
          feedItem?.id ? !removalArr.includes(feedItem.id) : true,
        ),
        feedPercent: Math.round((filteredStoryCount / totalStories) * 100),
      },
      false,
      "updated filters and percentage",
    );
  },

  async updateResultsSortBy(sortOrder) {
    const { filteredFeedItems } = get();
    const sortedFeedItems = filteredFeedItems.sort((a, b) => {
      if (sortOrder === "SCORE_DESC") {
        return (b.maxScore || 0) - (a.maxScore || 0);
      }
      if (sortOrder === "SCORE_ASC") {
        return (a.maxScore || 0) - (b.maxScore || 0);
      }
      if (sortOrder === "DATE_DESC" || sortOrder === "DATE_ASC") {
        const dateA = a.articleLastUpdateDate
          ? new Date(a.articleLastUpdateDate)
          : new Date(0);
        const dateB = b.articleLastUpdateDate
          ? new Date(b.articleLastUpdateDate)
          : new Date(0);

        if (sortOrder === "DATE_DESC") {
          return dateB.getTime() - dateA.getTime();
        }
        return dateA.getTime() - dateB.getTime();
      }
      return 0;
    });
    set(
      { filteredFeedItems: sortedFeedItems },
      false,
      "set filtered feed items",
    );
  },

  updateDateRange: (filterDays, startDate, endDate) => {
    if (filterDays) setDaysParametersToUrl(filterDays);
    set(
      {
        filterDays,
        startDate: startDate,
        endDate: endDate,
      },
      false,
      "updated range for days",
    );
    get().fetchFeed();
  },

  changeSort: (sortOrder) => {
    setSortByToUrl(sortOrder);
    set({ sortOrder }, false, "updated sort order");
    get().updateResultsSortBy(sortOrder);
  },

  updateRelationship: (filter, relationship) => {
    const { categoryAppliedFilters, updateFilterAndPercent } = get();
    const filterToUpdate = categoryAppliedFilters[filter];
    if (!filterToUpdate) return;

    filterToUpdate.relationship = relationship;
    set({ categoryAppliedFilters }, false, "updated category applied filters");

    updateFilterAndPercent();
  },

  addEditTopTopic: (newTopic, oldValue = false) => {
    const {
      categoryAppliedFilters,
      feedId,
      feedName: name,
      feedTerms: terms,
      syncTopTopics,
    } = get();
    const topics = categoryAppliedFilters?.["Top Topics"];
    if (!topics) return;
    if (topics.itemCounts === undefined) {
      topics.itemCounts = {};
    }

    if (oldValue) {
      topics.itemCounts[newTopic] = 0;
      delete topics.itemCounts[oldValue];
    } else if (!(newTopic in topics.itemCounts)) {
      topics.itemCounts[newTopic] = 0;
    } else {
      return;
    }

    categoryAppliedFilters["Top Topics"] = topics;
    set(
      { categoryAppliedFilters },
      false,
      "updated cat applied filters from add/edit",
    );
    if (feedId && name && terms && topics)
      syncTopTopics(
        { ...topics, itemCounts: topics.itemCounts || {} },
        feedId,
        name,
        terms,
      );
  },

  syncTopTopics: async (topTopics, feedId, name, terms) => {
    const tags = Object.keys(topTopics.itemCounts ?? {});
    const data = { name, terms, tags };
    try {
      await client.mutation(UPDATE_KNOWN_TAGS_MUTATION, {
        data,
        feedId,
      });
    } catch (err) {
      console.error(err);
    }
  },

  removeTopTopic: (topic: string) => {
    const {
      categoryAppliedFilters,
      feedId,
      feedName: name,
      feedTerms: terms,
      syncTopTopics,
    } = get();
    const topTopics = categoryAppliedFilters?.["Top Topics"];
    if (!topTopics || topTopics.itemCounts === undefined) return;
    // Remove topic from itemCounts
    if (typeof topTopics.itemCounts === "object") {
      delete topTopics.itemCounts[topic];
    }
    // Remove topic from selected topics array
    const selectedTopics = topTopics.selected;
    if (selectedTopics) {
      const topicIndex = selectedTopics.indexOf(topic);
      if (topicIndex > -1) {
        selectedTopics.splice(topicIndex, 1);
      }
    }
    categoryAppliedFilters["Top Topics"] = topTopics;
    set({ categoryAppliedFilters }, false, "updated cat from removing topic");
    if (feedId && name && terms)
      syncTopTopics(
        { ...topTopics, itemCounts: topTopics.itemCounts || {} },
        feedId,
        name,
        terms,
      );
  },
  tierConfig: {},
  setTierConfig: (config) =>
    set({ tierConfig: config }, false, "updated tier config"),

  recalculateFilterCount: () => {
    const { categoryAppliedFilters, filteredFeedItems, knownTags } = get();
    calculateNewCounts(
      categoryAppliedFilters,
      false,
      filteredFeedItems,
      knownTags,
    );
  },

  updateCategoryAppliedFilters: (
    categoryFiltersToUpdate,
    categoryKey = false,
  ) => {
    const { categoryAppliedFilters } = get();
    const newCategoryAppliedFilters = { ...categoryAppliedFilters };

    for (const [key, value] of Object.entries(categoryFiltersToUpdate)) {
      newCategoryAppliedFilters[key] = {
        ...newCategoryAppliedFilters[key],
        ...value,
      };
    }

    updateAppliedFiltersToUrl(newCategoryAppliedFilters);

    set(
      { categoryAppliedFilters: newCategoryAppliedFilters },
      false,
      "update category applied filters",
    );

    get().updateFilterAndPercent();

    if (categoryKey) get().updateFilterCounts(categoryKey);
    else get().updateFilterCounts();
  },

  updateSelectedFilters: (categoryKey, selectedOptions) => {
    const { categoryAppliedFilters, updateCategoryAppliedFilters } = get();
    const catFilter = categoryAppliedFilters[categoryKey];

    if (!catFilter) return;

    catFilter.selected = selectedOptions; // assign selectedOptions to catFilter.selected

    updateAppliedFiltersToUrl(categoryAppliedFilters);
    updateCategoryAppliedFilters(categoryAppliedFilters, categoryKey);
  },
});
